<template>
  <div class="form-box">
    <van-tabs v-model="activeName" color="#1989fa" @click="tabClick">
      <van-tab
        v-for="item in repireList"
        :key="item.dictKey"
        :title="item.dictValue"
        :name="item.dictKey"
      ></van-tab>
    </van-tabs>
    <van-form @submit="onSubmit">
       <van-field
         v-model="publicRepair.fieldValue"
         label="报修地点"
         label-width="4.2em"
         is-link
         readonly
         placeholder="请选择楼宇、单元、房间"
         :rules="[{ required: true }]"
         @click="showFieldValue = true"
       />
      <van-popup v-model="showFieldValue" round position="bottom">
        <van-picker
            show-toolbar
            title="请选择房产"
            value-key="fullName"
            :columns="roomList"
            @cancel="showFieldValue = false"
            @confirm="confirmRoom"
        />
      </van-popup>

      <h2 class="category-title-label">报修项目</h2>
      
      <div class="row">
        <van-radio-group v-model="radio" direction="horizontal">
          <van-radio
            v-for="item in typeList"
            :key="item.id"
            :name="item.id"
            class="item-radio"
            @click="radioChange(item.id, item.dictValue)"
          >
            {{ item.dictValue }}
          </van-radio>
        </van-radio-group>
        <div class="line"></div>
      </div>
      <van-field
        class="marginDiv"
        v-model="remark"
        label-width="4.2em"
        label="报修描述"
        placeholder="请输入报修描述"
        rows="5"
        autosize
        type="textarea"
        :rules="[{ required: true }]"
      />

      <van-field name="imgList" label-width="4.2em" label="报修照片">
        <template #input>
          <van-uploader
            v-model="imgList"
            @oversize="oversize"
            :beforeDelete="beforeDelete"
            :max-size="5 * 1024 * 1024"
            :max-count="5"
            :afterRead="afterRead"
            :preview-size="80"
          />
        </template>
      </van-field>
      <!-- <div class="row">
        <div class="label">报修照片</div>
        <div class="upload-img">
          <van-uploader
            v-model="imgList"
            multiple
            :max-count="3"
            :max-size="5 * 1024 * 1024"
            @oversize="oversize"
            :afterRead="afterRead"
          />
        </div>
      </div> -->
      <template v-if="activeName == 8002">
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="orderTime"
          label="预约时间"
          label-width="4.2em"
          placeholder="点击选择时间"
          :rules="[{ required: true, message: '必填项不能为空' }]"
          @click="showVisitTime = true"
        />
        <van-popup v-model="showVisitTime" round position="bottom">
          <van-datetime-picker
            type="datetime"
            title="选择时间"
            :min-date="minDate"
            @confirm="onConfirmShowVisitTime"
            @cancel="showVisitTime = false"
          >
          </van-datetime-picker>
        </van-popup>
      </template>

      <van-field
        class="marginDiv"
        v-model="userName"
        label="联系人"
        label-width="4.2em"
        placeholder="请填写真实姓名"
        :rules="[{ required: true, message: '必填项不能为空' }]"
      />
      <van-field
        v-model="userPhone"
        label="联系电话"
        label-width="4.2em"
        placeholder="请填写联系电话"
        :rules="[{ required: true, message: '必填项不能为空' }]"
      />
      <div style="margin: 16px">
        <van-button block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { levelList, repairSave, repairDetail } from '@/api/repairApi'
import { getDictionary } from '@/api/registerApi'
import { myProperty } from '@/api/houseApi.js'
import axios from '@/utils/axios'
import { dateFormat } from '@/utils/date'

export default {
  data() {
    return {
      radio: '',
      repireList: [
        {
          dictValue: '公共报修',
          dictKey: '8001',
        },
        {
          dictValue: '个人报修',
          dictKey: '8002',
        },
      ],
      activeName: '8001',
      showFieldValue: false,
      showVisitTime: false,
      typeList: [], //报修项目列表
      repairItem: '', // 报修项目
      repair_itemid: '', // 报修项目id
      repair_item: '', // 报修项目
      userInfo: {}, // 登陆用户信息
      // repireList: [], // 报修类型
      // 公共报修相关参数
      publicRepair: {
        estateName: '', // 小区name
        estateId: '', // 小区id
        options: {},
        cascaderValue: '',
        fieldValue: '',
        buildingId: '', // 楼宇id
        unitId: '', // 单元id
        roomId: '', // 房间id
      },
      remark: '', // 报修描述
      // 报修照片
      imgList: [],
      roomList: [],
      ownerPicList: [],
      orderTime: '', // 上门时间
      minDate: new Date(),
      userName: '', // 联系人
      userPhone: '', // 联系电话
      id: '', // 报修详情id
    }
  },
  created() {
    // 获取我的房产
    this.getMyProperty()
    // 获取报修项目
    this.getType()
    // 获取ulr参数
    let options = this.$route.query
    if (options.id) {
      this.id = options.id
      this.getDetail()
    }
    if (options.repairType) {
      this.activeName = options.repairType
    }
    // this.getbuild()
    this.userInfo = this.$store.getters.userInfo
    this.userName = this.$store.getters.userInfo.name
    this.userPhone = this.$store.getters.userInfo.phone
    // this.publicRepair.estateId = this.$store.getters.userInfo.estateId
    // this.publicRepair.estateName = this.$store.getters.userInfo.estateName
  },
  watch: {
    activeName(newVal, oldVal) {
      this.getType()
    },
  },
  computed: {
    pid() {
      const { activeName, repireList } = this

      const item = repireList.find(item => {
        return item.dictKey == activeName
      })

      return item.id
    },
  },
  methods: {
    // 删除图片
    beforeDelete(file, detail) {
      this.imgList.splice(detail.index, 1)
      this.ownerPicList.splice(detail.index, 1)
    },
    getMyProperty() {
      myProperty().then(res => {
        if(res.code == 200) {
          this.roomList = res.data
          this.roomList.map(v => {
            v.fullName = v.estateName + '-' + v.fullRoomName
          })
        }
      })
    },
    // 获取报修类型
    async getType() {
      // 获取报修项目
      const data = await getDictionary('repair_item')

      if (data.code == 200) {
        this.typeList = data.data
      }
    },
    // 获取详情信息
    async getDetail() {
      const { id } = this
      let openid = this.$store.getters.openId

      this.$store.commit('SET_IS_LOADING', true)
      const res = await repairDetail({
        id,
        openid,
      })

      if (res.code == 200) {
        this.$store.commit('SET_IS_LOADING', false)
        const {
          address,
          buildingId,
          unitId,
          roomId,
          repairItem,
          repairItemId,
          remark,
          ownerPic,
          orderTime,
          estateId,
        } = res.data

        this.publicRepair.buildingId = buildingId
        this.publicRepair.unitId = unitId
        this.publicRepair.roomId = roomId
        this.publicRepair.estateId = estateId
        // this.publicRepair.fieldValue = address.replace(/[^\d]/g, '')
        this.publicRepair.fieldValue = address
        this.repairItem = repairItem
        this.publicRepair.repairItemId = repairItemId
        this.repair_itemid = repairItemId // 报修项目id
        this.repair_item = repairItem // 报修项目
        this.radio = repairItemId // 报修项目
        this.remark = remark
        this.ownerPicList = (ownerPic && ownerPic.split(',')) || []
        this.imgList =
          (ownerPic &&
            ownerPic.split(',').map(item => {
              return {
                url: item,
              }
            })) ||
          []
        this.orderTime = orderTime
      }
    },
    radioChange(id, name) {
      this.repair_itemid = id // 报修项目id
      this.repair_item = name // 报修项目
    },
    // 上门时间
    onConfirmShowVisitTime(val) {
      this.orderTime = dateFormat(val, 'YYYY-MM-DD HH:II:SS')
      this.showVisitTime = false
    },
    // 公共/个人微信-切换
    tabClick(name) {
      this.activeName = name
      // this.repairItem = "";
    },
    // 图片超出大小
    oversize() {
      this.$toast({
        type: 'warning',
        message: '图片不能超过5M~',
        duration: 500,
      })
    },
    // 图片上传后，获取返回的参数，在提交时当做参数传递给后台，uploadImgList是图片上传后返回的图				片信息列表
    afterRead(file) {
      // 上传图
      file.status = 'uploading'
      file.message = '上传中'
      let url = file.file
      const that = this

      let formdata = new FormData()
      formdata.append('file', url)

      axios({
        baseURL: '/minio',
        url: '/minio/villageUpload?keepFileName=1&bucketName=village',
        method: 'post',
        data: formdata,
      })
        .then(res => {
          if (res.code == 200) {
            that.ownerPicList.push(res.data.url)
            file.status = 'done'
            file.message = ''
          } else {
            file.status = 'failed'
            file.message = '上传失败'
          }
        })
        .catch(() => {
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    
    // 通过小区获取楼宇、单元、房间等信息
    getbuild() {
      levelList({
        openid: this.$store.getters.openId,
        communityId: String(this.$store.getters.userInfo.estateId),
      }).then(res => {
        this.publicRepair.options = res.data[0].children
        this.rewriteKey(this.publicRepair.options)
      })
    },
    // 替换名称
    rewriteKey(val) {
      val.forEach(item => {
        item.text = item.title
        if (item.children) {
          this.rewriteKey(item.children)
        }
      })
    },
    confirmRoom(val) {
      this.publicRepair.buildingId = val.buildingId
      this.publicRepair.unitId = val.unitId
      this.publicRepair.roomId = val.roomId
      this.publicRepair.estateId = val.estateId

      
      this.publicRepair.fieldValue = val.fullName
      this.showFieldValue = false
    },
    onFieldValue({ selectedOptions }) {
      this.showFieldValue = false
      this.publicRepair.fieldValue = selectedOptions.map(option => option.text).join('')
      let fieldValues = selectedOptions.map(option => option.value)
      this.publicRepair.buildingId = fieldValues[0]
      this.publicRepair.unitId = fieldValues[1]
      this.publicRepair.roomId = fieldValues[2]
      if (fieldValues.length < 3) {
        return this.$toast({
          type: 'warning',
          message: '楼宇、单元、房间信息不全',
          duration: 1000,
        })
      }
    },
    clearData() {
      this.userName = ''
      this.userPhone = ''
      this.remark = ''
      this.repairItem = ''
      this.repair_itemid = ''
      this.repair_item = ''
      this.fieldValue = ''
      this.cascaderValue = ''
      this.orderTime = ''
      this.imgList = []
      this.ownerPicList = []
      this.publicRepair.fieldValue = ''
      this.publicRepair.buildingId = ''
      this.publicRepair.unitId = ''
      this.publicRepair.roomId = ''
    },
    onSubmit() {
      if(!this.repair_itemid) {
        return this.$toast({
          type: 'warning',
          message: '请选择报修项目',
          duration: 1000,
        })
      } 
      let req = {
        openid: this.$store.getters.openId,
        repairType: this.activeName, // 报修类型
        repairItemId: this.repair_itemid, //报修项目id
        // repairItem: this.repair_item, //报修项目name
        // estateName: this.publicRepair.estateName, //小区name
        estateId: this.publicRepair.estateId, //小区id
        buildingId: this.publicRepair.buildingId, // 楼宇id
        unitId: this.publicRepair.unitId, // 单元id
        roomId: this.publicRepair.roomId, // 房间id
        remark: this.remark, // 报修描述
        ownerName: this.userName,
        ownerPhone: this.userPhone,

        address: this.publicRepair.fieldValue,
        ownerPic: this.ownerPicList.join(','), // 报修图片
      }
      if (this.activeName == '8002') {
        req.orderTime = this.orderTime
      }
      repairSave(req).then(res => {
        if (res.code == 200) {
          let _this = this
          return this.$toast({
            type: 'success',
            message: '提交成功',
            duration: 500,
            onClose: function () {
              _this.clearData()
              // _this.$router.push({
              //   path: '/repairRecords',
              // })
              window.location.href = '/repairRecords'
              // _this.$router.go(-1)
              
            },
          })
        } else {
          return this.$toast({
            type: 'error',
            message: res.msg,
            duration: 500,
          })
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}
.row {
  padding: 0 14px;
  // margin-top: 15px;
  .line {
    width: 100%;
    height: 1px;
    color: #f5f6f7;
  }
  .van-field {
    padding: 2px 4px;
  }

  .item-radio {
    margin: 10px;
  }
}
.item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 5px;
  margin: 5px 0;
}
.marginDiv {
  margin: 5px 0;
}
.van-cell {
  width: auto;
  min-height: 35px;
  min-width: 300px;
  overflow-x: auto;
  margin-top: 5px;
}
.typeName {
  display: flex;
}
::v-deep .typeName .van-radio {
  width: auto;
  min-width: 95px;
}
</style>
